import { useEffect, useState } from "react";
import { connectWallet, getCurrentWalletConnected } from "./interact.js";
import Web3 from "web3"
import {ADDRESS, ABI} from "./config.js"
import Slider from '@material-ui/core/Slider';

const Static = (props) => {

  //State variables
  const [walletAddress, setWallet] = useState("");
  const [status, setStatus] = useState("");
  const [contract, setContract] = useState(null)
  const [totalSupply, setTotalSupply] = useState(0)
  const [saleStarted, setSaleStarted] = useState(false)
  const [price, setPrice] = useState(0)
  const [instantiated, setInstantiated] = useState(false)
  const [tokenId, setTokenId] = useState(0)
  const [how_many, set_how_many] = useState(1)
  const [claimStarted, setClaimStarted] = useState(false)

  function valuetext(value) {
    if(value === 3)
    {
       value = 5;
       set_how_many(value);
       return;
    }
    if(value === 4)
    {
       value = 10
    }
    if(value === 5)
    {
       value = 20
    }
   set_how_many(value);
 }
 const sliderMarks = [
  {
    value: 1,
    label: '1',
  },
  {
    value: 2,
    label: '2',
  },
  {
    value: 3,
    label: '5',
  },
  {
    value: 4,
    label: '10',
  },
  {
    value: 5,
    label: '20',
  },
 ];

  useEffect(async () => {
    const { address, status } = await getCurrentWalletConnected();
    setWallet(address)
    setStatus(status);
    instantiate();
    callContractData();
    addWalletListener();
    setTokenId();
  }, []);

  const connectWalletPressed = async () => {
    const walletResponse = await connectWallet();
    setStatus(walletResponse.status);
    setWallet(walletResponse.address);
  };

  const onMintPressed = async () => { 
    if (contract) {

      if(!saleStarted)
      {
        alert("Public mint period not active")
        return
      }
 
      const owlPrice = Number(price) * how_many

      let gasAmount = 0
      try
      {
        gasAmount = await contract.methods.mint(how_many).estimateGas({from: walletAddress, value: owlPrice})
      }
      catch (error)
      {
        alert(error)
        return
      }
      console.log("estimated gas",gasAmount)

      console.log({from: walletAddress, value: price})

      contract.methods
            .mint(how_many)
            .send({from: walletAddress, value: owlPrice, gas: String(gasAmount)})
            .on('transactionHash', function(hash){
              console.log("transactionHash", hash)
            })
          
    } else {
        console.log("Wallet not connected")
    }

  };

  async function instantiate() {
    if (typeof window.web3 !== 'undefined') {
      // Use existing gateway
      window.web3 = new Web3(window.ethereum);
     
    } else {
      alert("No Ethereum interface injected into browser. Read-only access");
    }

    window.ethereum.enable()
      .then(function (accounts) {
        window.web3.eth.net.getNetworkType()
        // checks if connected network is mainnet
        .then((network) => {console.log(network);if(network != "main"){alert("You are on " + network+ " network. Change network to mainnet or you won't be able to do anything here")} });  
        let wallet = accounts[0]
        setWallet(wallet)
        setInstantiated(true)
        callContractData(wallet)

  })
  .catch(function (error) {
  // Handle error. Likely the user rejected the login
  console.error(error)
  })
  }

  async function callContractData() {
    const owlContract = new window.web3.eth.Contract(ABI, ADDRESS)
    setContract(owlContract)

    const salebool = await owlContract.methods.saleIsActive().call() 
    setSaleStarted(salebool)

    const claimbool = await owlContract.methods.claimIsActive().call() 
    setClaimStarted(claimbool)

    const sqPrice = await owlContract.methods.mintRate().call()
    setPrice(sqPrice)
  }

  function addWalletListener() {
    if (window.ethereum) {
      window.ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length > 0) {
          setWallet(accounts[0]);
          setStatus("");
        } else {
          setWallet("");
          setStatus("Connect your Metamask wallet to mint 🦊");
        }
      });
    } else {
      setStatus(
        <p>
          {" "}
          🦊{" "}
          <a target="_blank" href={`https://metamask.io/download.html`}>
            You must install Metamask, a virtual Ethereum wallet, in your
            browser.
          </a>
        </p>
      );
    }
  }

  return (
    <div className="overflow">
    <header className="header">
       <div className="cont flex">
          <div className="header__top flex">
             <div className="social flex animated">
                <a target="_blank" href="https://www.instagram.com/barnowlznft/"><img src="/soc12.svg" alt=""/></a>
                <a target="_blank" href="https://twitter.com/barnowlznft"><img src="/soc2.svg" alt=""/></a>
                <a target="_blank" href="https://discord.gg/naaRpvwxBE"><img src="/soc3.svg" alt=""/></a>
                <a target="_blank" href="https://opensea.io/collection/barn-owlz"><img src="/soc4.svg" alt=""/></a>
                <a target="_blank" href="https://niftygateway.com/marketplace/collectible/0x2a281305a50627a22ec3e7d82ae656adfee6d964?filters[onSale]=true"><img src="/nifty-gateway.png" alt=""/></a>
             </div>
             <button className="btn header__btn" onClick={connectWalletPressed}>
                {walletAddress.length > 0 ? (
                   "Connected: " +
                   String(walletAddress).substring(0, 6) +
                   "..." +
                   String(walletAddress).substring(38)
                ) : (
                   <span>Connect Metamask Wallet</span>
                )}
             </button>
          </div>
          
          
 
          <div className="header__logo">
             <picture>
                <source media="(min-width: 1440px)" srcset="/logo1.png" />
                <source media="(min-width: 768px)" srcset="/logo1440.png" />
                <img src="/logo768.png" alt="BarnOwlz" />
             </picture>
          </div>
 
       </div>
    </header>
 
       <section className="lore" id="lore">
          <h2>Barn Owlz</h2>
          <div className="cont flex flex-2col">
             <div>
                <img src="/lore.jpg" alt=""/>
             </div>
 
             <div className="description">
                <p>In July 2021, an artist and a developer came together with a common goal: change the NFT space. Barn Owlz birthed new opportunities that cemented its spot on the Ethereum (ETH) blockchain. </p>
                 <p>  At the time of mint, most projects were minting 10,000 pieces at 0.06-0.08 ETH. However, Barn Owlz wanted to be different than “most” projects out there. Releasing a 3,000-piece collection with a mint price of 0.02 ETH, it's no surprise Barn Owlz sold out in mere minutes! Better yet, they did so with a max transaction limit of 3 Owlz. This transaction limit was to allow people, who had never owned an NFT, a chance at minting. </p>
                  <p> The Barn Owlz have always been an all-inclusive project that offers a wide variety of traits that reflects the diversity within the NFT community. Proceeds from the mint have been donated to The Wildlife Conservation Society (WCS), aiding their efforts in maintaining wildlife and their beautiful habitats. Barn Owlz also collaborated with Land of Hypnos (technoveins, @girlwhoshivers) to produce an alien owl where all proceeds were donated to St. Jude Children's Hospital. The Barn Owlz will continue to participate in charity gifting in hopes to spread positivity throughout the NFT space. Hootie hooooo!
                </p>
 
             </div>
          </div>
       </section>

       <section className="mint" id="mint">
       <h2>Tree Squirrelz</h2>
         <div className="cont">
          <h3>Select how many Squirrelz to mint.</h3>

          <h3>Barn Owlz holders can claim their free Tree Squirrelz from May 5th 6:00PM EST to May 7th at 6:00PM EST, after which public mint will open for .025ETH.</h3>
          
          <div className="slider">
               
            <Slider
               defaultValue={1}
               getAriaValueText={valuetext}
               aria-labelledby="discrete-slider"
               valueLabelDisplay="auto"
               step={1}
               marks={sliderMarks}
               min={1}
               max={5}
            />
            
            </div>
            <button id="mintButton" onClick={onMintPressed} className="btn">
            {"Mint Squirrelz"}
            </button>
         </div>
      </section>
 
       <section className="team" id="team">
          <h2>The Team</h2>
          <div className="cont grid">
             <div>
                <img alt="" src="/team/1.jpg" />
                <h4>Welcome To Chili's</h4>
                <h5>Artist</h5>
                <p></p>
             </div>
             <div>
                <img alt="" src="/team/2.jpg" />
                <h4>Partialdubs</h4>
                <h5>Developer</h5>
                <p> </p>
             </div>
             <div>
                <img alt="" src="/team/5.jpg" />
                <h4>ImLiterallyETH</h4>
                <h5>Project Manager</h5>
                <p> </p>
             </div>
             <div>
                <img alt="" src="/team/3.jpg"/>
                <h4>yungiv</h4>
                <h5>Community Liaison</h5>
                <p></p>
             </div>
          </div>
       </section>
      {/*
       <section className="roadmap" id="roadmap">
          <div className="cont">
             <h2>ROADMAP</h2>
             <div className="roadmap__phase">
                <h3>PHASE 1 (Against Owl Oddz)</h3>
                <p>Discord Revamp/Community Games - The Barnyard Discord will get a new makeover to increase community engagement. This includes playing games for prizes and WL opportunities with other partnered projects.</p>
                <p>Charity 1/1 Auction Collabs - In efforts to support the community, the Barn Owlz will auction 1/1 Owlz created in collaboration with other artists in the space. The proceeds will be donated to a charity chosen by the community.</p>
                <p>Merch 1.0 Drop - The Barn Owlz will drop their long awaited first collection of merch.</p>
             </div>
          </div>
       </section>
                */}
 
       <footer className="footer">
          <div className="social flex">
             <a target="_blank" href="https://www.instagram.com/barnowlznft/"><img src="/soc12.svg" alt=""/></a>
             <a target="_blank" href="https://twitter.com/barnowlznft"><img src="/soc2.svg" alt=""/></a>
             <a target="_blank" href="https://discord.gg/naaRpvwxBE"><img src="/soc3.svg" alt=""/></a>
             <a target="_blank" href="https://opensea.io/collection/barn-owlz"><img src="/soc4.svg" alt=""/></a>
             <a target="_blank" href="https://niftygateway.com/marketplace/collectible/0x2a281305a50627a22ec3e7d82ae656adfee6d964?filters[onSale]=true"><img src="/nifty-gateway.png" alt=""/></a>
          </div>
       </footer>
    
       <div className="container">
       </div>   
 </div>
 
   )
 };
 
 export default Static;
